import React, { PureComponent } from 'react';

import styled, { css } from 'styled-components';

import Box from '../atoms/Box/Box';
import Button from '../atoms/Button/Button';
import Text from '../atoms/Text/Text';

import AnalyticsManager from '../../utils/analyticsManager';

import { Align } from '@shared/enums/align';
import { Color } from '@shared/enums/button-color';
import { I18nTranslation } from '@shared/interfaces/i18n';
import { Size } from '@shared/enums/button-size';
import { _scrollToElement } from '@shared/animate/functions';
import { breakpoint } from 'theme';
import { withTranslation } from 'i18n';

type BannerStartProps = {
  onClickTrial: () => void;
  affiliate?: boolean;
  t: I18nTranslation;
};

class BannerStart extends PureComponent<BannerStartProps> {
  t = key => this.props.t(`banner.${key}`);

  handleScroll = () => {
    const elem = document.querySelector('#boxSubscriptions');
    _scrollToElement(elem as HTMLElement);
    const focusableElements = elem.querySelectorAll<HTMLElement>(
      'a,button,input,textarea,select,details,[tabindex]:not([tabindex="-1"])'
    );
    
    if (focusableElements.length > 0){
      focusableElements[0].focus();
    }
  };

  render() {
    const { onClickTrial, affiliate } = this.props;

    return (
      <Box maxWidth={71} role="region" ariaLabel="Product Options">
        <Box mb={3.5} mr={2.75} ml={2.75} smMl={0} smMr={0}>
          <BoxStart
            backgroundColor="kuroiBlack"
            smPt={3}
            smPb={3}
            pl={2}
            pr={2}
            pt={2.75}
            pb={2.75}
          >
            <BoxStartText
              smMb={2}
              smPr={0}
              smAlign={Align.Center}
              mb={1}
              pr={1}
            >
              <Text
                smSize={2}
                size={2.5}
                color="white"
                weight="bold"
                lineHeight="normal"
              >
                {this.t('start')}
              </Text>
              <br />
              <Text smSize={1.25} size={1.5} color="white" lineHeight="normal">
                {this.t('available')}
              </Text>
            </BoxStartText>
            <BoxStartButtons>
              {!affiliate && (
                <BoxFirstButton smMb={1}>
                  <ButtonBannerStart
                    onClick={() => {
                      AnalyticsManager.shared.pricingPage.addTiffStartToday();
                      AnalyticsManager.googleLegacy.pricingPage.addTiffStartToday();
                      onClickTrial();
                    }}
                    colorType={Color.FullTurquoiseBlack}
                    sizeType={Size.FullWidthDefault}
                  >
                    {this.t('try')}
                  </ButtonBannerStart>
                </BoxFirstButton>
              )}

              <BoxSecondButton>
                <StyledLink
                  href="#"
                  onClick={(e)=>{
                    e.preventDefault();
                    this.handleScroll();
                  }}
                  >
                    {this.t('buy')}
                  </StyledLink>
              </BoxSecondButton>
            </BoxStartButtons>
          </BoxStart>
        </Box>
      </Box>
    );
  }
}

export default withTranslation('common')(BannerStart);

const BoxStart = styled(Box)`
  display: flex;
  flex-direction: column;

  ${breakpoint.m`
    flex-direction: row;
    flex-wrap: wrap;
    border-radius: 0.25em;
  `}
`;

const BoxStartText = styled(Box)`
  ${breakpoint.m`
    width: 50%;
  `}
`;

const BoxStartButtons = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  ${breakpoint.m`
    flex-direction: row;
    align-items: center;
    width: 50%;
  `}
`;

const BoxFirstButton = styled(Box)`
  ${breakpoint.m`
    width: 48%;
    margin-right: 1.5em;
  `}
`;

const BoxSecondButton = styled(Box)`
  ${breakpoint.m`
    width: 48%;
  `}
`;

const ButtonStyles = css`
  text-transform: uppercase;
  line-height: normal;
`;

const ButtonBannerStart = styled(Button)`
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
  font-size: 1.25em;
  ${ButtonStyles};
`;

const StyledLink = styled.a`
  display: flex;
  color: turquoise;
  text-decoration: none;
  border: 1px solid turquoise;
  border-radius: 0.25em;
  width: 100%;
  align-items: center;
  background: transparent;
  justify-content: center;
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
  font-size: 1.25em;
  ${ButtonStyles};
`
