import React from 'react';
import { connect } from 'react-redux';

import styled, { css } from 'styled-components';

import { AppState } from '@redux/types';

import Box from '../atoms/Box/Box';
import Circle from '../atoms/Circle/Circle';

import { Currency } from '@shared/interfaces/currency';
import { breakpoint } from 'theme';

type CurrenciesListProps = {
  currencies: Currency[];
  currency: Currency;
  className?: string;
  onSelect?: (currency: Currency) => void;
  isOpen?: boolean;
};

class CurrenciesList extends React.Component<CurrenciesListProps> {
  render() {
    const { currencies, currency, className, onSelect } = this.props;

    return (
      <Wrapper className={className}>
        {currencies.map(item => (
          <Item
            key={item.iso}
            selected={item.iso === currency.iso}
            onClick={() => onSelect(item)}
          >
            <StyledCircle size={1.5}>{item.symbol}</StyledCircle>
            {item.iso}
          </Item>
        ))}
      </Wrapper>
    );
  }
}

export default connect((state: AppState) => ({
  currencies: state.currencies,
  currency: state.currency.currentCurrency,
}))(CurrenciesList);

const Wrapper = styled(Box)`
  max-height: 376px;
  overflow: auto;

  color: ${({ theme }) => theme.colors.cornflowerBlue};

  background: ${({ theme }) => theme.colors.white};

  ${breakpoint.down('m')`width: 100%;`}
`;

const StyledCircle = styled(Circle)`
  margin-right: 12px;

  font-size: 2.25em;
  line-height: 1;
  border: 2px solid ${({ theme }) => theme.colors.turquoise};

  color: ${({ theme }) => theme.colors.turquoise};
`;

const selectedMixin = css`
  & {
    font-weight: 600;
  }

  & ${StyledCircle} {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.turquoise};
  }
`;

type ItemProps = {
  selected?: boolean;
};

const Item = styled(Box)<ItemProps>`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin: 40px 0;
  padding: 0 25px;

  cursor: pointer;

  &:hover {
    ${selectedMixin}
  }

  &:first-child {
    margin-top: 20px;
  }

  &:last-child {
    margin-bottom: 20px;
  }

  ${({ selected }) => selected && selectedMixin};
`;
