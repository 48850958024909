import React, { PureComponent } from 'react';

import styled from 'styled-components';

import CenterMessage from './CenterMessage';
import { MEDIA_POINT } from 'theme';

const PopupTitle = styled.div`
  margin-top: 42px;
  margin-left: 16px;
  font-size: 1.9em;
  font-weight: 700;

  @media screen and (min-width: ${MEDIA_POINT.M}px) {
    position: absolute;
    font-size: 2.19em;
    top: 15px;
    left: 30px;
    margin-top: 0;
    margin-left: 0;
  }
`;

const ImageWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 6em);

  @media screen and (min-width: ${MEDIA_POINT.M}px) {
    height: 100%;
  }
`;

const Picture = styled.picture`
  max-width: 100%;
  max-height: 100%;

  @media screen and (min-width: ${MEDIA_POINT.M}px) {
    margin-top: 50px;
  }
}`;

const Img = styled.img`
  width: 281px;
  max-width: 100%;
  max-height: 100%;

  @media screen and (min-width: ${MEDIA_POINT.M}px) {
    width: 519px;
  }
  @media screen and (min-width: ${MEDIA_POINT.MLG}px) {
    width: 537px;
  }
`;

type WeChatQRCodePopupProps = {
  onClose: () => void;
};

class WeChatQRCodePopup extends PureComponent<WeChatQRCodePopupProps> {
  render() {
    return (
      <CenterMessage onClose={this.props.onClose}>
        <PopupTitle>WeChat QR Code</PopupTitle>
        <ImageWrap>
          <Picture>
            <source
              srcSet="/images/weChatQRMob.jpg 1x"
              media={`(max-width: ${MEDIA_POINT.LTM}px)`}
            />
            <source
              srcSet="/images/weChatQRTab.jpg 1x"
              media="(max-width: 1439px)"
            />
            <source
              srcSet="/images/weChatQR@2x.jpg 1x"
              media="(min-width: 1440px)"
            />
            <Img src="/images/weChatQRMob.jpg" alt="WeChat QR Code" />
          </Picture>
        </ImageWrap>
      </CenterMessage>
    );
  }
}

export default WeChatQRCodePopup;
