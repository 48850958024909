import React, { Component } from 'react';

import styled from 'styled-components';

import ExternalLink from '../atoms/ExternalLink/ExternalLink';

import { ROUTE } from '../../utils';

import { HaveAccountPopup } from '@hoc/HaveAccountPopup';
import { ShowHaveAccountPopup } from '@shared/interfaces/show-have-account-popup';

const ExternalLinkExtend = styled(ExternalLink)`
  padding: 0.75em 0;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  transition: color 0.2s;
  display: block;
  color: inherit;
`;

type FooterSubItemProps = {
  className: string;
  href: string;
  name: string;
  showHaveAccountPopup?: ShowHaveAccountPopup;
};

class FooterSubItem extends Component<FooterSubItemProps> {
  showHaveAccountPopup = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    this.props.showHaveAccountPopup(null, event);
  };

  render() {
    return (
      <li className={this.props.className}>
        <ExternalLinkExtend
          href={this.props.href}
          onClick={
            this.props.href === ROUTE.ACCOUNT.INDEX
              ? this.showHaveAccountPopup
              : null
          }
        >
          {this.props.name}
        </ExternalLinkExtend>
      </li>
    );
  }
}

const styledFooterSubItem = styled(FooterSubItem)`
  color: ${({ theme }) => theme.transparentColors.white60};

  ${props =>
    props.href &&
    `
    &:hover {
      color: ${props.theme.colors.white};
    }
  `}
`;

export default HaveAccountPopup(styledFooterSubItem);
