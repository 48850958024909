import React, { Component } from 'react';

import styled from 'styled-components';

import Box from '../atoms/Box/Box';
import ExternalLink from '../atoms/ExternalLink/ExternalLink';

import SliderArrowIcon from '@public/icons/sliderArrow.svg';

const ExternalLinkExtend = styled(ExternalLink)`
  font-weight: 600;
  color: ${props => props.theme.colors.white};
  line-height: 1.5;
`;

type FooterMenuItemTitleProps = {
  className?: string;
  href?: string;
  title: string;
  isMobile?: boolean;
  isActive?: boolean;
};

class FooterMenuItemTitle extends Component<FooterMenuItemTitleProps> {
  render() {
    return (
      <Box className={this.props.className}>
        <ExternalLinkExtend href={this.props.href}>
          {this.props.title}
        </ExternalLinkExtend>

        {this.props.isMobile && (
          <SliderArrowIcon
            width={10}
            height={10}
            style={{
              transform: `rotate(${this.props.isActive ? -90 : 90}deg)`,
            }}
          />
        )}
      </Box>
    );
  }
}

const styledFooterMenuItemTitle = styled(FooterMenuItemTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default styledFooterMenuItemTitle;
