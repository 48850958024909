import React, { PureComponent } from 'react';

import Box from '../atoms/Box/Box';
import Text from '../atoms/Text/Text';

import { Align } from '@shared/enums/align';

type AppTitleProps = {
  title: string;
};


class AppTitle extends PureComponent<AppTitleProps> {
  render() {
    return (
      <Box mb={5.625} smMb={3.75}>
        <Text smSize={2.5} size={3.125} color="white" lineHeight="normal" align={Align.Center} component="h1">
          {this.props.title}
        </Text>
      </Box>
    );
  }
}

export default AppTitle;
