import React, { Fragment, PureComponent } from 'react';

import styled from 'styled-components';

import Box from '../atoms/Box/Box';
import ExternalLink from '../atoms/ExternalLink/ExternalLink';
import Text from '../atoms/Text/Text';

import { Align } from '@shared/enums/align';
import { I18nTranslation } from '@shared/interfaces/i18n';
import { Target } from '@shared/enums/target';
import { withTranslation } from 'i18n';

type AdditionalInfoCompleteHeartProps = {
  t: I18nTranslation;
};

class AdditionalInfoCompleteHeart extends PureComponent<AdditionalInfoCompleteHeartProps> {
  render() {
    const { t } = this.props;

    return (
      <Fragment>
        <Box align={Align.Center} mb={0.75}>
          <Text color="white" size={1.25} lineHeight="normal">
            {t('pricing.completeHeart', { price: '99.99' })}
          </Text>
        </Box>
        <Box mb={5.125} smMb={3}>
          <List>
            <Item>
              <ExternalLink
                href="https://apps.apple.com/us/app/complete-heart/id1297232784"
                target={Target.Blank}
              >
                iPad
              </ExternalLink>
            </Item>
            <Item>
              <ExternalLink
                href="https://apps.apple.com/us/app/complete-heart/id1297233893?mt=12"
                target={Target.Blank}
              >
                Mac
              </ExternalLink>
            </Item>
            <Item>
              <ExternalLink
                href="https://www.microsoft.com/en-us/p/complete-heart/9nkdb5vv0d87?activetab=pivot:overviewtab"
                target={Target.Blank}
              >
                Windows
              </ExternalLink>
            </Item>
          </List>
        </Box>
      </Fragment>
    );
  }
}

export default withTranslation('common')(AdditionalInfoCompleteHeart);

const List = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Item = styled.li<Partial<AdditionalInfoCompleteHeartProps>>`
  display: flex;
  list-style: none;
  font-size: 1.25em;
  line-height: normal;
  margin-right: 0.5em;
  align-items: center;

  > a {
    color: ${props => props.theme.colors.turquoise};
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:first-child {
    &:before {
      display: none;
    }
  }

  &:before {
    content: '';
    vertical-align: middle;
    width: 6px;
    height: 6px;
    background-color: ${props => props.theme.colors.white};
    display: inline-block;
    border-radius: 50%;
    margin-right: 0.5em;
    flex-shrink: 0;
  }
`;
