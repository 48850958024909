import React, { Component, Fragment } from 'react';

import styled from 'styled-components';

import Box from '../atoms/Box/Box';
import LanguageAndCurrencySelector from '../molecules/LanguageAndCurrencySelector';
import MenuItem from '@components/organisms/FooterMenuItem';

import { FooterContent } from '@shared/interfaces/footer-content';

import SocialNetworks from './SocialNetworks';

import { breakpoint } from 'theme';

const Col = styled(Box)`
  padding: 0 1.25vw;
  box-sizing: border-box;
  margin-bottom: 3em;

  ${breakpoint.down('mlg')`
    width: 26%;
  `}

  ${breakpoint.down('mm')`
    width: 37.5vw;
    padding: 0 2.5vw;
  `}

  ${breakpoint.down('sm')`
    width: 75vw;
    padding: 0 3.75vw;
  `}
`;

const LanguageAndCurrencySelectorWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 1.25vw 3em 1.25vw;
  padding-left: 12.5vw;
  padding-right: 12.5vw;

  ${breakpoint.down('mm')`
    flex-direction: column;

    & > div:last-child {
      order: -1;
    }
  `};

  ${breakpoint.down('ltm')`
    display: none;
  `}
`;

type FooterLargeProps = {
  className?: string;
  menu?: FooterContent[];
  isLoadingCountry: boolean;
  isChinaLocation: boolean;
};

class FooterLarge extends Component<FooterLargeProps> {
  render() {
    return (
      <Fragment>
        <div className={this.props.className}>
          {this.props.menu.map(item => {
            return (
              <Col key={item.id} ariaLabel={item.title.name}>
                <MenuItem
                  name={item.id}
                  item={item}
                  isChinaLocation={this.props.isChinaLocation}
                  isLoadingCountry={this.props.isLoadingCountry}
                />
              </Col>
            );
          })}
        </div>
        <LanguageAndCurrencySelectorWrapper>
          <LanguageAndCurrencySelector />

          <SocialNetworks
            isChinaLocation={this.props.isChinaLocation}
            isLoadingCountry={this.props.isLoadingCountry}
          />
        </LanguageAndCurrencySelectorWrapper>
      </Fragment>
    );
  }
}

const styledFooterLarge = styled(FooterLarge)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 4.5em 12.5vw 0;
`;

export default styledFooterLarge;
