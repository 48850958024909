import React, { PureComponent } from 'react';

import Box from '../atoms/Box/Box';
import Text from '../atoms/Text/Text';

import { Align } from '@shared/enums/align';
import { I18nTranslation } from '@shared/interfaces/i18n';
import { withTranslation } from 'i18n';

type AdditionalInfoProps = {
  className?: string;
  t: I18nTranslation;
};

class AdditionalInfo extends PureComponent<AdditionalInfoProps> {
  render() {
    const { className, t } = this.props;

    return (
      <Box className={className} align={Align.Center}>
        <Text smSize={1.25} size={1.375} color="white" lineHeight="normal">
          {t('pricing.saleTip')}
        </Text>
      </Box>
    );
  }
}

export default withTranslation('common')(AdditionalInfo);
