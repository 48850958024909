import React, { FC } from 'react';

import { Svg } from '@components/atoms/Socials/Svg';

export const BilibiliIcon: FC = () => {
  return (
    <Svg viewBox="0 0 34 32">
      <g>
        <path
          d="M16.994 8.146c-3.7 0-7.398.011-11.096-.01-.72-.004-1.257.287-1.543.874-.231.473-.369 1.039-.372 1.565-.03 4.02-.018 8.04-.017 12.061 0 .699-.025 1.4.017 2.096.068 1.12.906 1.959 2.033 1.961 7.32.016 14.64.013 21.961.004.93-.001 1.572-.504 1.903-1.34.135-.34.154-.744.155-1.119.01-4.538.006-9.076.006-13.615 0-.152.01-.306-.01-.456-.135-.997-.707-2.074-2.172-2.045-3.62.07-7.244.023-10.865.024M.01 17.384c0-2.163-.006-4.326.002-6.489.009-2.361.895-4.325 2.883-5.658.557-.374 1.357-.508 2.054-.535 1.977-.078 3.959-.04 5.939-.06.15-.001.298-.089.447-.136-.072-.127-.12-.277-.22-.376-.615-.618-1.263-1.204-1.853-1.843-.27-.292-.522-.658-.624-1.032-.11-.406.077-.83.467-1.063.446-.268.945-.266 1.337.073.646.558 1.242 1.174 1.859 1.767.814.783 1.617 1.58 2.45 2.343.174.16.452.28.686.287 1.074.03 2.15-.002 3.223.02.56.01.854-.383 1.183-.703 1.14-1.106 2.254-2.238 3.384-3.354.162-.16.342-.31.535-.43.466-.294 1.11-.202 1.478.19.376.402.413 1.02.015 1.455-.607.665-1.268 1.282-1.885 1.938-.2.211-.334.48-.499.724.272.058.544.165.816.167 1.72.016 3.439.016 5.158.006 1.53-.008 2.7.676 3.645 1.818 1.052 1.27 1.49 2.758 1.497 4.367.02 4.342.009 8.683.007 13.024 0 1.34-.305 2.628-1.02 3.748-.945 1.484-2.278 2.457-4.154 2.457-.138 0-.277.008-.414-.001-.562-.038-.973.032-1.155.72-.182.693-1.11 1.206-1.815 1.187-.7-.018-1.461-.567-1.685-1.276-.149-.472-.425-.638-.913-.636-3.838.013-7.675.013-11.513 0-.522-.002-.813.178-.99.677-.276.777-.958 1.237-1.706 1.24-.763.003-1.553-.509-1.792-1.273-.161-.514-.451-.657-.945-.643-.843.025-1.688.045-2.486-.32C1.87 29.062.913 27.85.429 26.29c-.241-.775-.38-1.608-.4-2.418-.053-2.162-.018-4.326-.018-6.489"
          transform="translate(-1170 -431) translate(1118 378) translate(52 53)"
        />
        <path
          d="M6.928 16.984c-.388.075-.565-.121-.627-.51-.085-.533-.186-1.062-.276-1.593-.082-.482.03-.626.488-.718 1.855-.375 3.71-.754 5.562-1.142.307-.064.517.01.584.353.117.604.218 1.211.326 1.817.06.335-.058.526-.378.591-1.278.259-2.556.522-3.833.79-.617.13-1.23.275-1.846.412M27.093 17l-1.982-.417c-1.212-.255-2.423-.517-3.638-.76-.398-.079-.537-.284-.447-.71.118-.559.209-1.123.31-1.685.066-.36.254-.48.593-.408 1.425.301 2.852.587 4.279.88.48.098.959.197 1.438.297.276.058.393.236.343.535-.102.61-.198 1.22-.307 1.827-.065.36-.18.451-.589.44M15.789 23c-1.277-.12-2.08-.6-2.7-1.35-.06-.072-.114-.236-.077-.268.245-.21.49-.444.789-.566.138-.057.413.094.594.197.236.135.418.352.662.466.436.205.868.157 1.2-.195.314-.334.615-.68.895-1.037.252-.323.403-.335.665-.003.22.277.43.56.66.83.557.653 1.36.684 1.987.084.489-.467 1.043-.454 1.483.066.075.09.068.318-.008.416-.82 1.051-2.48 1.9-4.039.797-.267-.19-.476-.172-.766-.022-.482.248-1.002.439-1.345.585"
          transform="translate(-1170 -431) translate(1118 378) translate(52 53)"
        />
      </g>
    </Svg>
  );
};
