import React, { PropsWithChildren, PureComponent } from 'react';

import Box from '../atoms/Box/Box';
import { Col } from '../atoms/Grid';
import Text from '../atoms/Text/Text';

type QuestionProps = {
  title: string;
  role?: string;
};

class Question extends PureComponent<PropsWithChildren<QuestionProps>> {
  render() {
    return (
      <Col
        xs={12}
        sm={6}
        role={this.props.role}
        aria-label={this.props.title}
      >
        <Box mb={3.75}>
          <Box mb={0.75}>
            <Text
              color="cornflowerBlue"
              weight={600}
              smSize={1}
              size={1.125}
              lineHeight={1.55}
            >
              {this.props.title}
            </Text>
          </Box>
          <Text lineHeight={1.4} color="cornflowerBlue" smSize={1} size={1.125}>
            {this.props.children}
          </Text>
        </Box>
      </Col>
    );
  }
}

export default Question;
