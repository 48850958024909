import React, { PureComponent } from 'react';

import Box from '../atoms/Box/Box';
import Text from '../atoms/Text/Text';

type SaleTitleProps = {
  title: string;
};

class SaleTitle extends PureComponent<SaleTitleProps> {
  render() {
    return (
      <Box mb={3.125}>
        <Text color="white" size={1.125} lineHeight="normal">
          <span dangerouslySetInnerHTML={{ __html: this.props.title }} />
        </Text>
      </Box>
    );
  }
}

export default SaleTitle;
