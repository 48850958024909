import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import styled from 'styled-components';

import { AppState } from '@redux/types';
import {
  _getUserLocationCountry,
  _isLoadingCountry,
} from '@redux/reducers/country/selectors';
import Copyright from '@components/atoms/Copyright/Copyright';
import Loader from '../atoms/Loader/Loader';
import FooterLarge from '@components/organisms/FooterLarge';
import FooterSmall from '@components/organisms/FooterSmall';
import {
  FooterContent,
  FooterContentItem,
  FooterContentTitle,
} from '@shared/interfaces/footer-content';
import { I18nTranslation } from '@shared/interfaces/i18n';
import Observer from '@hoc/Observer';
import { breakpoint } from 'theme';
import { withTranslation } from 'i18n';

export type IntersectionCallback = (
  entries: IntersectionObserverEntry[],
  observer: IntersectionObserver
) => void;

type FooterProps = {
  className: string;
  t: I18nTranslation;
  isLoadingCountry: boolean;
  isChinaLocation: boolean;
};

class Footer extends PureComponent<FooterProps> {
  state = {
    loading: true,
  };

  get footerContent(): FooterContent[] {
    /**
     * Zahachka
     * Translations might not be loaded yet, so we need to check if the translations are loaded
     */
    const footerTranslationsObject = this.props.t('footer', {
      returnObjects: true,
    });
    const footer =
      typeof footerTranslationsObject === 'string'
        ? {}
        : footerTranslationsObject;

    return Object.entries(footer).map<FooterContent>(
      ([id, { title, items }]: [
        string,
        { title: FooterContentTitle; items: FooterContentItem[] }
      ]) => {
        const itemsArray: FooterContentItem[] = Object.entries(items).map(
          ([itemId, data]: [string, FooterContentTitle]) => {
            return { id: itemId, ...data };
          }
        );

        return { id, title, items: itemsArray };
      }
    );
  }

  handleIntersectionCallback: IntersectionCallback = (entry, observer) => {
    const target = entry[0];
    if (target.isIntersecting) {
      this.setState({
        loading: false,
      });
      observer.unobserve(target.target);
    }
  };

  render() {
    const loader = <Loader />;
    const content = (
      <div className={this.props.className}>
        <FooterLarge
          menu={this.footerContent}
          isChinaLocation={this.props.isChinaLocation}
          isLoadingCountry={this.props.isLoadingCountry}
        />
        <FooterSmall
          menu={this.footerContent}
          isChinaLocation={this.props.isChinaLocation}
          isLoadingCountry={this.props.isLoadingCountry}
        />
        <Copyright />
      </div>
    );

    return (
      <footer role="contentinfo">
        <Observer onChange={this.handleIntersectionCallback}>
          {this.state.loading ? loader : content}
        </Observer>
      </footer>
    );
  }
}

const styledFooter = styled(withTranslation('common')(Footer))`
  background: ${props => props.theme.colors.cornflowerBlue};

  ${FooterLarge} {
    ${breakpoint.down('ltm')`
      display: none;
    `}
  }

  ${FooterSmall} {
    ${breakpoint.m`
      display: none;
    `}
  }
`;

const withConnect = connect((state: AppState) => {
  const userLocationCountry = _getUserLocationCountry(state);
  const CHINA_ID = 47;

  return {
    isChinaLocation: userLocationCountry.id === CHINA_ID,
    isLoadingCountry: _isLoadingCountry(state),
  };
});

export default withConnect(styledFooter);
