import React, { FC, memo } from 'react';

import styled from 'styled-components';

import { compose } from 'redux';

import Box from '../atoms/Box/Box';
import Button from '../atoms/Button/Button';
import ExternalLink from '../atoms/ExternalLink/ExternalLink';
import Text from '../atoms/Text/Text';

import AnalyticsManager from '@utils/analyticsManager';
import { getUrl } from '@utils/origin';

import { Align } from '@shared/enums/align';
import { Color } from '@shared/enums/button-color';
import { Currencies } from '@shared/enums/currency';
import { LANGUAGE } from '@shared/application/constants';
import { Size } from '@shared/enums/button-size';
import { breakpoint } from 'theme';
import { i18n, useTranslation, withTranslation } from 'i18n';

const CardInstitutional: FC = memo(() => {
  const { t: tProp, ready } = useTranslation('common');
  const t = (key, options?) => tProp(`cardInstitutional.${key}`, options);

  /**
   * Zahachka
   * Translations might not be loaded yet, so we need to check if the translations are loaded
   */
  const features = ready ? t('features', { returnObjects: true }).map((feature, i) => (
    <Item key={i}>{feature}</Item>
    )) : [];

  const getNewLocation = lang => {
    switch (lang) {
      case LANGUAGE.Spanish:
        return 'https://www.elsevier.com/es-es/products/complete-anatomy/contact-us';
      case LANGUAGE.German:
        return 'https://www.elsevier.com/de-de/products/complete-anatomy/contact-us';
      case LANGUAGE.English:
        return 'https://www.elsevier.com/products/complete-anatomy/contact-us';
      case LANGUAGE.French:
        return 'https://www.elsevier.com/fr-fr/products/complete-anatomy/contact-us';
      case LANGUAGE.Chinese:
        return 'https://www.elsevier.com/zh-cn/products/complete-anatomy/contact-us';
      default:
        return 'https://www.elsevier.com/products/complete-anatomy/contact-us';
    }
  };

  const handleClick = () => {
    AnalyticsManager.google.pricingPage.addToCart({
      name: `institutional`,
      price: '',
      currency: Currencies.Empty,
    });
    AnalyticsManager.googleLegacy.pricingPage.addToCart({
      name: `Institutional demo request`,
      price: '',
      currency: Currencies.Empty,
    });
    AnalyticsManager.shared.pricingPage.addRequestDemoInstitutional();
    window.location.href = getNewLocation(i18n.language);
  };

  return (
    <Wrapper backgroundColor="white" align={Align.Center}>
      <Box>
        <Box smMb={1.25} mb={2.625}>
          <Text size={2} color="cornflowerBlue" lineHeight="normal">
            {t('title')}
          </Text>
        </Box>
        <Box maxWidth={16.25} smMb={1}>
          <Text color="cornflowerBlue" size={0.875} lineHeight="normal">
            {t('text')}
          </Text>
        </Box>
      </Box>
      <List>{features}</List>
      <WrapperButtons>
        <ButtonExtend
          onClick={handleClick}
          colorType={Color.Abyss}
          sizeType={Size.Fifth}
        >
          {t('contact')}
        </ButtonExtend>
        <ExternalLink
          href={getUrl('https://3d4medical.com/institutional')}
          onClick={() => {
            AnalyticsManager.google.addItemView('institutional');
          }}
        >
          <Text color="abyss" weight={600}>
            {t('learn')}
          </Text>
        </ExternalLink>
      </WrapperButtons>
    </Wrapper>
  );
});

export default compose(withTranslation('common'))(CardInstitutional);

const Wrapper = styled(Box)`
  cursor: default;
  margin-left: 1em;
  margin-right: 1em;
  margin-bottom: 2em;
  padding: 2.5em 1.75em 1.5em 1.75em;
  border-radius: 0.25em;
  border: 1px solid ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 16.125em;
  height: 29.125em;

  ${breakpoint.m`
    width: 34.25em;
  `}

  ${breakpoint.down('m')`
    height: auto;
  `}
`;

const List = styled.ul`
  list-style-type: disc;
  margin-left: auto;
  margin-right: auto;
  padding-left: 28px;
  text-align: left;
`;

const Item = styled.li`
  color: ${({ theme }) => theme.colors.kuroiBlack};

  &:not(:last-child) {
    margin-bottom: 1em;
  }

  ${breakpoint.m`
    &:not(:last-child) {
      margin-bottom: 2em;
    }
  `}

  ${breakpoint.down('m')`
    &:last-child {
      margin-bottom: 1em;
    }
  `}
`;

const WrapperButtons = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonExtend = styled(Button)`
  margin-bottom: 1em;
`;
