import React, { Component } from 'react';

import styled from 'styled-components';

import LanguageAndCurrencySelector from '../molecules/LanguageAndCurrencySelector';
import SubItem from '../molecules/FooterSubItem';
import Title from '../molecules/FooterMenuItemTitle';
import SocialNetworks from '@components/organisms/SocialNetworks';

import { getUrl } from '@utils/origin';

import { Accordion, AccordionList, AccordionValue } from './Accordion';
import { FooterContent } from '@shared/interfaces/footer-content';

type FooterSmallProps = {
  className?: string;
  menu?: FooterContent[];
  isLoadingCountry: boolean;
  isChinaLocation: boolean;
};

class FooterSmall extends Component<FooterSmallProps> {
  render() {
    return (
      <div className={this.props.className}>
        {this.props.menu.map((item, i) => (
          <Accordion key={i} duration={200}>
            {props => (
              <Wrapper>
                <AccordionValue {...props}>
                  <TitleExtend
                    title={item.title.name}
                    isMobile
                    isActive={props.on}
                  />
                </AccordionValue>
                <AccordionList {...props}>
                  <SubItems>
                    {item.items.map((subItem, ii) => (
                      <SubItem
                        key={ii}
                        href={getUrl(subItem.link)}
                        name={subItem.name}
                      />
                    ))}
                  </SubItems>
                </AccordionList>
              </Wrapper>
            )}
          </Accordion>
        ))}

        <SocialNetworksSmall
          isMobile
          isChinaLocation={this.props.isChinaLocation}
          isLoadingCountry={this.props.isLoadingCountry}
        />

        <LanguageAndCurrencySelectorSmall />
      </div>
    );
  }
}

export default styled(FooterSmall)``;

const TitleExtend = styled(Title)`
  font-weight: 700;
  font-size: 1.25em;
`;

const Wrapper = styled.div`
  padding: 20px 14%;
  border-bottom: 1px solid ${props => props.theme.colors.outerSpace};
`;

const SubItems = styled.ul`
  margin-top: 1em;
`;

const SocialNetworksSmall = styled(SocialNetworks)`
  padding: 20px 14%;
`;

const LanguageAndCurrencySelectorSmall = styled(LanguageAndCurrencySelector)`
  justify-content: center;
  padding-bottom: 3em;

  color: white !important;
`;
