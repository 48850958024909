import React, { Component } from 'react';

import { connect } from 'react-redux';

import styled from 'styled-components';

import { AppState } from '@redux/types';
import { SalesState } from '@redux/reducers/sales/types';
import {
  _getCaApp,
  _isLoadingApps,
} from '@redux/reducers/applications/selectors';
import { _getCurrency } from '@redux/reducers/profile/selectors';
import { _getSales } from '@redux/reducers/sales/selectors';
import { _isAddingToBasket } from '@redux/reducers/basket/selectors';
import { _isAuthorized } from '@redux/reducers/auth/selectors';
import { _isLoadingCountry } from '@redux/reducers/country/selectors';
import { addProductsToBasket } from '@redux/reducers/basket/action';
import { showChangePurchasedSubscriptionPopup } from '@redux/reducers/popups/action';

import Box from '../atoms/Box/Box';
import CardSubscription from '@components/organisms/CardSubscription';
import { PlansWithPricesProp } from '@components/organisms/OfferSection';

import { ROUTE } from '@utils/index';
import { hasDiscount } from '@utils/hasDiscount';
import { isCN } from '@utils/origin';
import { redirectToAliPay } from '@utils/redirectToAliPay';

import CardInstitutional from './CardInstitutional';
import { Currency } from '@shared/interfaces/currency';
import { NEW_BUNDLE, OLD_BUNDLE } from '@shared/subscription/constants';
import { Product } from '@shared/interfaces/product';
import Router from 'next/router';
import { Sale } from '@shared/interfaces/sale';
import { breakpoint } from 'theme';
import { priceFormat } from '@shared/markup/functions';

type CardsListProps = {
  dispatch: (data: any) => Promise<void>;
  currency: Currency;
  hasCurrentSale: boolean;
  application?: Product;
  isLoadingApps: boolean;
  isAddingToBasket: boolean;
  sale: Sale | SalesState;
  plansWithPrices?: PlansWithPricesProp[];
  isLoadingCountry: boolean;
  isAuthorized: boolean;
};

class CardsList extends Component<CardsListProps> {
  handleAddToBasket = products => {
    this.props.dispatch(addProductsToBasket(products)).then(() => {
      this.goToNextPage();
    });
  };

  goToNextPage = () => {
    // Redirect to the AliPay payment page by skipping the Select page
    isCN && this.props.isAuthorized
      ? redirectToAliPay(Router)
      : Router.push(ROUTE.CHECKOUT.SELECT);
  };

  handleShowChangePurchasedSubscriptionPopup = () => {
    this.props.dispatch(showChangePurchasedSubscriptionPopup());
  };

  checkPurchasedBundle = (array, bundle) => {
    return array.some(
      item =>
        item?.subscription?.bundle?.toLowerCase() === bundle &&
        item.subscription.is_purchased
    );
  };

  render() {
    const {
      currency,
      hasCurrentSale,
      application,
      isLoadingApps,
      isAddingToBasket,
      sale,
      plansWithPrices,
      isLoadingCountry,
    } = this.props;

    const hasPurchasedStudentLicense =
      this.checkPurchasedBundle(plansWithPrices, OLD_BUNDLE.STUDENT) ||
      this.checkPurchasedBundle(plansWithPrices, NEW_BUNDLE.STUDENT);

    return (
      <Wrapper id="boxSubscriptions">
        {plansWithPrices.map(item => {
          const subscription = item.subscription;
          const information = item.information;
          const isRecommended = !!subscription.recommended;
          const regPrice = priceFormat(currency.symbol, information.regPrice);
          const salePrice = priceFormat(currency.symbol, information.price);
          const hasSale = hasDiscount(information.price, information.regPrice);

          return (
            <CardSubscription
              key={subscription.id}
              option={subscription.card}
              hasSale={hasSale}
              isRecommendedSubscription={isRecommended}
              stringRegPrice={regPrice}
              stringSalePrice={salePrice}
              hasPurchasedStudentLicense={hasPurchasedStudentLicense}
              subscription={subscription}
              application={application}
              currencyISO={currency.iso}
              isLoadingApps={isLoadingApps}
              onAddToBasket={this.handleAddToBasket}
              onShowChangePurchasedSubscriptionPopup={
                this.handleShowChangePurchasedSubscriptionPopup
              }
              isAddingToBasket={isAddingToBasket}
              sale={sale}
              hasCurrentSale={hasCurrentSale}
              isLoadingCountry={isLoadingCountry}
            />
          );
        })}

        <CardInstitutional />
      </Wrapper>
    );
  }
}

const withConnect = connect((state: AppState) => {
  const application = _getCaApp(state);
  const sale = _getSales(state, application.id);
  const hasCurrentSale = Object.keys(sale).length !== 0;

  return {
    application,
    sale,
    hasCurrentSale,
    currency: _getCurrency(state),
    isLoadingApps: _isLoadingApps(state),
    isAddingToBasket: _isAddingToBasket(state),
    isLoadingCountry: _isLoadingCountry(state),
    isAuthorized: _isAuthorized(state),
  };
});

export default withConnect(CardsList);

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${breakpoint.m`
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 75em;
    margin: 0 auto;
  `}
`;
