import React, { FC } from 'react';

import { Svg } from '@components/atoms/Socials/Svg';

export const RedbookIcon: FC = () => {
  return (
    <Svg viewBox="0 0 32 32">
      <g>
        <path
          d="M27.281 13.695c.394-.023.752.002 1.087-.075.42-.096.661-.561.574-.969-.088-.414-.438-.683-.866-.666-.45.017-.763.319-.792.786-.017.267-.003.537-.003.924zm-12.35 5.23c-.876-.044-1.671-.09-2.468-.12-.075-.003-.188.07-.226.138-.27.5-.525 1.006-.79 1.52.087.038.139.079.191.08.77.017 1.541.033 2.312.036.066 0 .16-.07.194-.132.26-.488.51-.982.787-1.521zm-4.44.832c.15-.284.278-.515.393-.752.282-.576.688-1.124.347-1.83-.075-.156-.044-.365-.058-.55-.064-.853-.12-1.707-.198-2.56-.01-.122-.123-.332-.2-.337-.492-.03-.987-.016-1.57-.016.167 1.497.301 2.928.499 4.35.083.6.345 1.162.787 1.695zm-5.224-6.053h-.829c-1.02 0-1.015 0-1.085 1.035-.056.824-.006 1.68-.22 2.462-.204.744.093 1.254.394 1.822.128.24.26.478.42.777 1.344-1.885.96-4.018 1.32-6.096zm10.785.088l-.078-.111c-.585.166-1.233-.208-1.844.251-.01-.175.009-.349.08-.497.316-.662.648-1.317.998-2.023-.534 0-.99-.013-1.445.009-.115.005-.279.093-.327.19-.474.941-.946 1.886-1.382 2.846-.25.55 0 .91.609.945.265.015.532.002.875.002-.093.203-.148.33-.206.453-.231.49-.48.973-.69 1.472-.244.575-.027.92.595.953.498.025.999.014 1.498.003.097-.002.24-.053.28-.126.217-.395.41-.804.623-1.233-.104-.02-.157-.037-.21-.04-.902-.047-.898-.046-.494-.847.377-.747.746-1.498 1.118-2.247zm-10.76 5.38c-.039.715.393 1.28 1.034 1.394 1.068.192 1.763-.42 1.766-1.578.007-2.385.002-4.771 0-7.157 0-.134-.023-.269-.033-.382H6.352v7.723h-1.06zM20.35 12.02h-3.912v1.683h1.057v5.176c-.464 0-.891-.012-1.318.007-.142.006-.353.049-.409.146-.28.487-.52.998-.79 1.531h5.949v-1.678h-1.66v-5.208h1.083v-1.657zm1.209 1.671h1.09v1.437h-1.655v1.708h1.689v3.724h1.714v-3.717c.797 0 1.538-.011 2.279.006.29.007.56.143.577.467.029.594.008 1.19.008 1.818h-1.68c-.003.591.287.956.735 1.244 1.075.69 2.595-.024 2.645-1.267.031-.77.027-1.544-.001-2.315-.034-.9-.502-1.43-1.367-1.613-.194-.041-.391-.067-.645-.11 0-.425.02-.853-.004-1.277-.058-1.037-.73-1.707-1.775-1.793-.252-.02-.505-.034-.763-.05l-.05-.506h-1.65l-.063.545h-1.084v1.7zM0 0h32v32H0V0z"
          transform="translate(-1120 -477) translate(1118 378) translate(2 99)"
        />
        <path
          d="M24 14.016c.108-.006.172-.012.236-.013.751-.013.763-.006.764.452V15h-1v-.984z"
          transform="translate(-1120 -477) translate(1118 378) translate(2 99)"
        />
      </g>
    </Svg>
  );
};
