import React, { PureComponent } from 'react';

import styled from 'styled-components';

import { QueryState } from '@redux/reducers/queryParams/types';

import AppIcon from '../atoms/AppIcon/AppIcon';
import Box from '../atoms/Box/Box';
import AdditionalInfo from '../molecules/AdditionalInfo';
import AdditionalInfoCompleteHeart from '../molecules/AdditionalInfoCompleteHeart';
import AppName from '../molecules/AppName';
import AppTitle from '../molecules/AppTitle';
import BannerStart from '../molecules/BannerStart';
import BannerTry from '../molecules/BannerTry';
import ComparePlans from '../molecules/ComparePlans';
import LicenseFeatures from '../molecules/LicenseFeatures';
import SaleTimer from '../molecules/SaleTimer';
import SaleTitle from '../molecules/SaleTitle';
import AppNameInLine from '../organisms/AppNameInLine';
import CardsList from '../organisms/CardsList';
import ComparisonChart from '../organisms/ComparisonChart';
import Faq from '../organisms/Faq';
import Footer from '../organisms/Footer';

import { Align } from '@shared/enums/align';
import { Bundle } from '@shared/enums/bundle';
import { CA_ICON, SUBSCRIPTION_ICONS } from '@shared/markup/constants';
import { HaveAccountPopup } from '@hoc/HaveAccountPopup';
import { I18nTranslation } from '@shared/interfaces/i18n';
import { PricingCard } from '@shared/interfaces/pricing-card';
import { Product } from '@shared/interfaces/product';
import { Sale } from '@shared/interfaces/sale';
import {
  _getCards,
  _getInfoForSubscription,
  getTitlePricingPageNew,
} from '@shared/markup/functions';
import { _getSalePercent } from '@shared/price/functions';
import { _isPurchasedProducts } from '@shared/product/functions';
import { _scrollToElement } from '@shared/animate/functions';
import { breakpoint } from 'theme';
import { withTranslation } from 'i18n';

const BoxFirstScreen = styled(Box)`
  background-image: linear-gradient(
    to bottom,
    ${props => props.theme.colors.oxfordBlue},
    ${props => props.theme.colors.cornflowerBlue}
  );
`;

const AppIconExtend = styled(AppIcon)`
  margin-bottom: 1.125em;

  ${breakpoint.m`
    margin-bottom: 1.5em;
  `}
`;

const AdditionalInfoExtend = styled(AdditionalInfo)`
  margin-bottom: 4em;
`;

type PricingTemplateProps = {
  application?: Product;
  onLogin?: () => void;
  sale?: Sale;
  hasCurrentSale?: boolean;
  queryParams?: QueryState;
  prices: PricingCard[];
  hasCompleteHeart: boolean;
  showHaveAccountPopup?: (
    p: () => void,
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
  t: I18nTranslation;
};

class PricingTemplate extends PureComponent<PricingTemplateProps> {
  handleScroll = () => {
    const elem: HTMLElement = document.querySelector('#features-table');
    _scrollToElement(elem);
    const header: HTMLElement = document.querySelector('#features-table h2');
    if (header) {
      header.focus();
    }
  };

  getRecommendedPlan = (plans = []) => {
    return plans.find(item => item.recommended);
  };

  render() {
    const {
      application,
      sale,
      hasCurrentSale,
      queryParams,
      prices,
      hasCompleteHeart,
      t,
    } = this.props;

    const plans = _getCards(application.subscriptions);
    const recommendedPlan = this.getRecommendedPlan(plans);
    let allSalePercents = [0];
    let plansWithPrices = plans.map((item, i) => {
      const information = _getInfoForSubscription(
        { bundle: item.bundle, interval: item.interval },
        prices
      );

      const salePercent = _getSalePercent(
        information.regPrice,
        information.price
      );
      allSalePercents = [...allSalePercents, parseInt(`${salePercent}`)];

      return {
        subscription: item,
        information,
        id: i,
      };
    });

    const salePercent = Math.max(...allSalePercents);

    const titleKey = getTitlePricingPageNew(
      recommendedPlan,
      salePercent,
      queryParams.affiliate,
      hasCompleteHeart,
      'showCourseTitle' in queryParams
    );

    const title = t(`pricing.title.${titleKey}`, { salePercent });

    const comparisonSubscriptions = plans.map(subscription => ({
      name: subscription.name,
      icon: subscription.card.icon,
      bundle: subscription.bundle,
    }));
    // Manually added
    comparisonSubscriptions.push({
      name: t('cardInstitutional.title'),
      icon: SUBSCRIPTION_ICONS.institutional,
      bundle: Bundle.Institutional,
    });

    const hasAnyPurchasedLicenses = _isPurchasedProducts(
      application.subscriptions
    );

    let additionalInfo = null;
    if (hasCompleteHeart) {
      additionalInfo = <AdditionalInfoCompleteHeart />;
    } else if (salePercent) {
      additionalInfo = <AdditionalInfoExtend />;
    }

    const onClickWithAccountBanner = e => {
      const { showHaveAccountPopup, onLogin } = this.props;

      showHaveAccountPopup(onLogin, e);
    };

    return (
      <Box
        as="main"
        ariaLabel="Body Content"
        backgroundColor="white"
      >

        {hasCurrentSale && sale.pricingPage && (
          <SaleTimer discountTitle={sale.pricingPage.discountTitle} />
        )}

        <BoxFirstScreen
          role="region"
          ariaLabel="License Pricing Plans"
          smPt={2.125} 
          smPb={1.75} 
          pt={5.125} 
          pb={4.5} 
          id="main-content"
        >
          <Box maxWidth={55} align={Align.Center} smPl={1} smPr={1}>
            <AppIconExtend icons={CA_ICON} smSize={72} size={108} />
            <AppName name="Complete Anatomy" />
            <AppTitle title={title} />

            {hasCurrentSale && sale.subtitle && (
              <SaleTitle title={sale.subtitle} />
            )}
          </Box>

          <CardsList plansWithPrices={plansWithPrices} />

          {additionalInfo}

          <ComparePlans onClick={this.handleScroll} />
        </BoxFirstScreen>

        {!hasAnyPurchasedLicenses && !queryParams.affiliate && (
          <BannerTry onClickTrial={onClickWithAccountBanner} />
        )}

        <ComparisonChart
          subscriptions={comparisonSubscriptions}
          features={application.comparison}
        />

        <LicenseFeatures />

        <Faq />

        {!hasAnyPurchasedLicenses && (
          <BannerStart
            onClickTrial={onClickWithAccountBanner}
            affiliate={queryParams.affiliate}
          />
        )}
      </Box>
    );
  }
}

export default withTranslation('common')(HaveAccountPopup(PricingTemplate));
