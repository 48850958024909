import React, { FC } from 'react';

import { Svg } from '@components/atoms/Socials/Svg';

export const MiniProgramIcon: FC = () => {
  return (
    <Svg viewBox="0 0 32 32">
      <g fillRule="evenodd">
        <path
          d="M69 99c8.83 0 16 7.17 16 16s-7.17 16-16 16-16-7.17-16-16 7.17-16 16-16zm1.4 16.665c0 1.197-.026 2.395.005 3.59.089 3.317-2.623 5.58-5.195 5.731-1.606.094-3.053-.28-4.309-1.355-2.754-2.357-2.475-6.584.57-8.635.726-.489 1.498-.865 2.394-.816.477.026.873.202 1.11.664.249.483.127.963-.185 1.316-.343.388-.783.683-1.192 1.002-.443.347-.99.595-1.331 1.028-.806 1.024-.567 2.399.46 3.182 1.209.924 2.779.93 3.908.015.602-.488.926-1.108.92-1.935-.014-2.438-.019-4.876.007-7.313.008-.659.072-1.333.224-1.97.541-2.27 2.687-4.031 4.903-4.154 1.486-.083 2.864.172 4.094 1.104 2.439 1.848 2.952 5.098 1.106 7.587-.93 1.254-2.171 2.096-3.761 2.105-1.034.006-1.52-.915-1.015-1.872.293-.555.755-.89 1.308-1.116.655-.268 1.226-.674 1.53-1.368.49-1.12.134-2.266-.897-3.006-1.141-.819-2.984-.643-3.973.379-.49.507-.67 1.143-.68 1.848v3.989z"
          transform="translate(-1171 -477) translate(1118 378)"
        />
      </g>
    </Svg>
  );
};
