import React from 'react';

import { connect } from 'react-redux';

import styled, { css } from 'styled-components';

import { AppState } from '@redux/types';
import { CountriesState } from '@redux/reducers/countries/types';
import { _isAuthorized } from '@redux/reducers/auth/selectors';
import { saveCurrency } from '@redux/reducers/currency/action';
import { showRegionSelectPopup } from '@redux/reducers/popups/action';

import Box from '../atoms/Box/Box';
import Circle from '../atoms/Circle/Circle';
import Icon from '../atoms/Icon';
import Image from '../atoms/Image/Image';
import Text from '../atoms/Text/Text';

import { isCN } from '@utils/origin';

import { Align } from '@shared/enums/align';
import CollapseArrow from '@public/icons/collapseArrowGreen.svg';
import CurrenciesList from './CurrenciesList';
import { Currency } from '@shared/interfaces/currency';
import { breakpoint } from 'theme';
import { withTranslation } from 'i18n';

type LanguageAndCurrencySelectorProps = {
  country: CountriesState;
  currency: Currency;
  isAuthorized: boolean;
  showRegionSelectPopup: () => void;
  saveCurrency: (currency: Currency) => void;
  className?: string;
  i18n: { language: string };
};

class LanguageAndCurrencySelector extends React.Component<LanguageAndCurrencySelectorProps> {
  state = {
    isListOpen: false,
  };

  onToggleList = () => {
    this.setState({
      isListOpen: !this.state.isListOpen,
    });
  };

  onSelectCurrency = currency => {
    this.onToggleList();

    this.props.saveCurrency(currency.iso);
  };

  onRegionClick = () => {
    this.props.showRegionSelectPopup();
  };

  getCurrentLanguage() {
    switch (this.props.i18n.language) {
      case 'en':
        return 'English';
      case 'es':
        return 'Español';
      case 'zh':
        return '简体中文';
      case 'fr':
        return 'Français';
      case 'de':
        return 'Deutsch';
    }
  }

  renderFlag() {
    const { country } = this.props;

    const flag = () => (
      <StyledCircle
        backgroundColor="white"
        size={2}
        smSize={2.125}
        onClick={this.onRegionClick}
      >
        <Image
          height="100%"
          src={country.flags[56]}
          srcSet={[country.flags[56], country.flags[140], country.flags[210]]}
          alt={country.name}
        />
      </StyledCircle>
    );

    const icon = () => (
      <StyledIcon
        size={2}
        smSize={2.125}
        name="earth"
        onClick={this.onRegionClick}
      />
    );

    return isCN ? icon() : flag();
  }

  render() {
    const { currency, isAuthorized, className } = this.props;
    const { isListOpen } = this.state;

    return (
      <BoxContainer alignItems={Align.Center} className={className}>
        {!isAuthorized && (
          <>
            {this.renderFlag()}
            <StyledText onClick={this.onRegionClick}>
              {this.getCurrentLanguage()}
            </StyledText>
          </>
        )}
        {/* Display currency selector everywhere except China */}
        {!isCN && (
          <>
            <StyledText onClick={this.onToggleList}>
              {currency.symbol} {currency.iso}
              <StyledCollapseArrow isOpen={isListOpen} />
            </StyledText>
            <StyledCurrenciesList
              onSelect={this.onSelectCurrency}
              isOpen={isListOpen}
            />
          </>
        )}
      </BoxContainer>
    );
  }
}

export default withTranslation()(
  connect(
    (state: AppState) => ({
      currencies: state.currencies,
      country: state.country.userLocation,
      currency: state.currency.currentCurrency,
      isAuthorized: _isAuthorized(state),
    }),
    (dispatch: (action) => void) => ({
      showRegionSelectPopup: () =>
        dispatch(showRegionSelectPopup({ force: true })),
      saveCurrency: iso => dispatch(saveCurrency(iso)),
    })
  )(LanguageAndCurrencySelector)
);

const BoxContainer = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  user-select: none;

  color: ${({ theme }) => theme.transparentColors.white60};
`;

const StyledCircle = styled(Circle)`
  cursor: pointer;
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const StyledText = styled(Text)`
  margin-left: 10px;

  white-space: nowrap;
  cursor: pointer;

  &:last-of-type:not(:first-of-type) {
    padding-left: 10px;

    border-left: 1px solid ${({ theme }) => theme.transparentColors.white60};
  }
`;

const StyledCollapseArrow = styled(CollapseArrow)`
  width: 15px;
  height: 9px;

  margin-left: 10px;

  transition: transform 200ms ease-in-out;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate3d(1, 0, 0, 180deg);
    `}
`;

const StyledCurrenciesList = styled(CurrenciesList)`
  position: absolute;
  bottom: 2em;
  right: 0;

  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};

  ${breakpoint.down('m')`bottom: 4.5em;`}
`;
