import React, { Component, Fragment } from 'react';

import styled from 'styled-components';

import SubItem from '../molecules/FooterSubItem';
import Title from '../molecules/FooterMenuItemTitle';

import { getUrl } from '@utils/origin';

import { FooterContent } from '@shared/interfaces/footer-content';

type FooterMenuItemProps = {
  name: string;
  item: FooterContent;
  isLoadingCountry: boolean;
  isChinaLocation: boolean;
};

class FooterMenuItem extends Component<FooterMenuItemProps> {
  renderTitleExtended = ({ title }) =>
    title.link !== '#' ? (
      <TitleExtend href={title.link} title={title.name} />
    ) : (
      <TitleExtend title={title.name} />
    );

  render() {
    const { name, item } = this.props;

    return (
      <Fragment>
        {this.renderTitleExtended(item)}
        <List>
          {item.items.map(({ id, link, name }) => (
            <SubItem key={id} href={getUrl(link, id)} name={name} />
          ))}
        </List>
      </Fragment>
    );
  }
}

export default FooterMenuItem;

const List = styled.ul`
  display: flex;
  flex-flow: column;

  height: 100%;
`;

const TitleExtend = styled(Title)`
  margin-bottom: 0.75em;
`;
