import React, { FC } from 'react';

import { Svg } from '@components/atoms/Socials/Svg';

export const InstagramIcon: FC = () => {
  return (
    <Svg viewBox="0 0 48 48">
      <g>
        <path
          className="st0"
          d="M24,4.3c6.4,0,7.2,0,9.7,0.1C36,4.6,37.3,5,38.2,5.3c1.1,0.4,1.9,1,2.8,1.8c0.8,0.8,1.4,1.6,1.8,2.8c0.3,0.8,0.7,2.1,0.8,4.5c0.1,2.5,0.1,3.3,0.1,9.7s0,7.2-0.1,9.7c-0.1,2.3-0.5,3.6-0.8,4.5c-0.4,1.1-1,1.9-1.8,2.8c-0.8,0.8-1.6,1.4-2.8,1.8c-0.8,0.3-2.1,0.7-4.5,0.8c-2.5,0.1-3.3,0.1-9.7,0.1s-7.2,0-9.7-0.1c-2.3-0.1-3.6-0.5-4.5-0.8c-1.1-0.4-1.9-1-2.8-1.8c-0.8-0.8-1.4-1.6-1.8-2.8C5,37.3,4.6,36,4.5,33.7c-0.1-2.5-0.1-3.3-0.1-9.7s0-7.2,0.1-9.7C4.6,12,5,10.7,5.3,9.8c0.4-1.1,1-1.9,1.8-2.8c0.8-0.8,1.6-1.4,2.8-1.8C10.7,5,12,4.6,14.3,4.5C16.8,4.3,17.6,4.3,24,4.3 M24,0c-6.5,0-7.3,0-9.9,0.1c-2.6,0.1-4.3,0.5-5.8,1.1C6.7,1.9,5.4,2.7,4,4C2.7,5.4,1.9,6.7,1.3,8.3c-0.6,1.5-1,3.3-1.1,5.8C0,16.7,0,17.5,0,24c0,6.5,0,7.3,0.1,9.9c0.1,2.6,0.5,4.3,1.1,5.8C1.9,41.3,2.7,42.6,4,44c1.3,1.3,2.7,2.2,4.3,2.8c1.5,0.6,3.3,1,5.8,1.1C16.7,48,17.5,48,24,48s7.3,0,9.9-0.1c2.6-0.1,4.3-0.5,5.8-1.1c1.6-0.6,2.9-1.4,4.3-2.8c1.3-1.3,2.2-2.7,2.8-4.3c0.6-1.5,1-3.3,1.1-5.8C48,31.3,48,30.5,48,24s0-7.3-0.1-9.9c-0.1-2.6-0.5-4.3-1.1-5.8C46.1,6.7,45.3,5.4,44,4c-1.3-1.3-2.7-2.2-4.3-2.8c-1.5-0.6-3.3-1-5.8-1.1C31.3,0,30.5,0,24,0L24,0z"
        />
        <path
          className="st0"
          d="M24,11.7c-6.8,0-12.3,5.5-12.3,12.3S17.2,36.3,24,36.3S36.3,30.8,36.3,24S30.8,11.7,24,11.7z M24,32c-4.4,0-8-3.6-8-8s3.6-8,8-8s8,3.6,8,8S28.4,32,24,32z"
        />
        <circle className="st0" cx="36.8" cy="11.2" r="2.9" />
      </g>
    </Svg>
  );
};
