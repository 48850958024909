import React, { FC } from 'react';

import { Svg } from './Svg';

export const WeChatIcon: FC = () => {
  return (
    <Svg viewBox="0 0 40 32">
      <g>
        <path
          d="M19.525 6.09c-1.16.016-1.994.859-1.97 1.994.022 1.133.906 1.971 2.042 1.938 1.138-.034 1.974-.907 1.95-2.035-.025-1.09-.9-1.912-2.022-1.897m-7.81 1.994c.017-1.154-.789-1.972-1.965-1.993-1.113-.02-2.005.812-2.03 1.894-.026 1.13.813 2.006 1.954 2.037 1.128.031 2.025-.82 2.042-1.938M14.71.01c3.783.057 7.246 1.063 10.215 3.42 1.901 1.508 3.272 3.398 3.971 5.717.206.684.144.785-.574.785-3.074-.003-5.923.778-8.46 2.472-3.146 2.1-5.022 4.942-5 8.774.003.625.153 1.253.276 1.872.116.582.057.71-.56.697-1.514-.032-3.027-.112-4.48-.582-.634-.205-1.18-.113-1.741.233-.837.516-1.699.996-2.57 1.454-.229.12-.533.102-.803.148-.033-.263-.137-.54-.087-.788.132-.654.327-1.296.504-1.94.229-.83.17-1.034-.516-1.536-1.703-1.246-3.051-2.778-3.922-4.683C-.303 13.28-.342 10.48.97 7.73 2.783 3.935 5.98 1.761 10.014.637 11.546.21 13.103-.055 14.71.01"
          transform="translate(-1118 -431) translate(1118 378) translate(0 53)"
        />
        <path
          d="M23.977 16.044c-.967 0-1.726.766-1.71 1.725.016.927.783 1.675 1.717 1.673.916-.003 1.667-.775 1.668-1.716.002-.971-.707-1.683-1.675-1.682m7.995 0c-.932 0-1.736.767-1.735 1.655.002.919.815 1.738 1.73 1.742.872.005 1.732-.83 1.74-1.685.007-.943-.773-1.714-1.735-1.712m-2.833-5.042c2.092-.051 4.953.899 7.377 2.942 2.562 2.16 3.894 4.916 3.372 8.336-.37 2.427-1.667 4.363-3.572 5.863-.689.543-.965 1.059-.62 1.897.173.421.241.891.312 1.346.03.19.01.481-.109.575-.107.085-.405.017-.571-.072-.736-.394-1.467-.798-2.174-1.24-.477-.296-.93-.312-1.468-.18-3.84.936-7.516.573-10.923-1.533-1.989-1.23-3.533-2.895-4.266-5.145-1.003-3.078-.495-5.951 1.593-8.446 2.582-3.084 6.01-4.321 11.05-4.343"
          transform="translate(-1118 -431) translate(1118 378) translate(0 53)"
        />
      </g>
    </Svg>
  );
};
