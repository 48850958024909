import styled from 'styled-components';

export const Svg = styled.svg`
  height: 36px;
  fill: ${props => props.theme.colors.olsoGray};
  transition: fill 0.2s;

  &:hover {
    fill: ${props => props.theme.colors['bombay']};
  }
`;
